import { useEffect, useState } from "react";
import styles from "./action.module.css";
import classNames from "classnames";
const Links = (props) => {
	const [active, setActive] = useState(false);

	useEffect(() => {
		if (active) {
			document.querySelectorAll("a").forEach((el) => {
				el.classList.add(styles.highlight_links);
			});
		} else {
			document.querySelectorAll("a").forEach((el) => {
				el.classList.remove(styles.highlight_links);
			});
		}
	}, [active]);

	return (
		<button
			onClick={() => setActive(!active)}
			className={classNames(styles.btn, {
				[styles.active]: active,
			})}
		>
			<div className={styles.active_label}>
				<svg height="16px" viewBox="0 -960 960 960" width="16px" fill="white">
					<path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
				</svg>
			</div>
			<svg height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
				<path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" />
			</svg>
			<ul className={styles.steep}></ul>
			<span>Highlight Links</span>
		</button>
	);
};

export default Links;
