import { useEffect, useState } from "react";
import styles from "./action.module.css";
import classNames from "classnames";

const Cursor = (props) => {
	const [active, setActive] = useState(false);

	useEffect(() => {
		const elements = document.querySelectorAll("*");
		if (active) {
			const svg = `
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <circle cx="16" cy="16" r="15" stroke="black" stroke-width="2" fill="transparent"/>
                </svg>
            `;
			// Convertir el SVG en una URL de datos
			const svgDataUri = `data:image/svg+xml;base64,${btoa(svg)}`;
			// Aplicar el cursor personalizado a cada elemento
			elements.forEach((element) => {
				element.style.cursor = `url(${svgDataUri}) 16 16, auto`;
			});
		} else {
			elements.forEach((element) => {
				element.style.cursor = ``;
			});
		}
	}, [active]);

	return (
		<button
			onClick={() => setActive(!active)}
			className={classNames(styles.btn, {
				[styles.active]: active,
			})}
		>
			<div className={styles.active_label}>
				<svg height="16px" viewBox="0 -960 960 960" width="16px" fill="white">
					<path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
				</svg>
			</div>
			<svg height="24px" viewBox="0 -960 960 960" width="24px" fill="black">
				<path d="m320-410 79-110h170L320-716v306ZM551-80 406-392 240-160v-720l560 440H516l144 309-109 51ZM399-520Z" />
			</svg>
			<ul className={styles.steep}></ul>
			<span>Cursor</span>
		</button>
	);
};

export default Cursor;
